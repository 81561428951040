<template>
    <div>
        <Tabs @on-click="onChangeTab">
            <TabPane label="推荐科研专家">
                <div class="neirong">
                    <div class="nei_rong_box">
                        <div class="flex-wrap">
                            <div class="result-expert-hover result-hover" v-for="(item, index) in expertList"
                                 :key="index">
                                <div style="height:16px;background:#1767E0;border-radius: 8px 8px 0 0;"></div>
                                <div style="padding: 30px 30px 10px 30px">
                                    <div class="flex-column flex-align-center"
                                         style="border-bottom: 1px dashed #D5E7FA;padding-bottom:20px">
                                        <span style="font-size: 24px;">{{ item.expertName }}</span>
                                        <span style="font-size: 16px">{{ item.unitsName }}</span>
                                    </div>
                                    <div class="flex-column flex-align-center" style="padding-top:20px">
                                        <div>
                                            推荐指数：<span>{{ item.expertsCount }}</span>
                                        </div>
                                        <div style="height: 42px;padding-left: 10px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">
                      <span>
                        技术关键词：{{item.keywords || '暂无'}}
                      </span>
                                        </div>
                                    </div>
                                </div>
                                <div @click.stop="openZoosUrl" class="contact-btn">
                                    联系专家
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Page
                        :total="expertTotal"
                        show-elevator
                        :current.sync='matchParams.pageNum'
                        style="margin-top: 50px; float: right;margin-bottom: 20px"
                        @on-change="changePageE"
                />
            </TabPane>
            <TabPane label="推荐科研成果">
                <div class="neirong">
                    <div class="nei_rong_box">
                        <div class="flex-wrap">
                            <div @click="seeResult(item)" class="result-ach-hover result-hover"
                                 v-for="(item, index) in resultList" :key="index">
                                <div>
                                    <div style="padding-left:10px;border-bottom:1px dashed #D5E7FA;margin-bottom:20px">
                                        <div style="height:54px">
                                            <span style="color:#333333;font-size:18px">{{ item.title }}</span>
                                        </div>
                                        <div class="flex-align-center" style="margin:20px 0">
                      <span style="font-size: 14px;background-color: #fcdfc8;color:#fea464;padding:5px;border-radius:4px;margin-right:20px">{{
                        item.achieveType
                      }}</span>
                                            <span style="margin-right:20px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;line-height: 25px;text-align: left;color:#333333"><span
                                                    style="color:#999999;">归属高校：</span>{{ item.colleges }}</span>
                                            <span style="overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;line-height: 25px;text-align: left;color:#1767E0"><span
                                                    style="color:#999999;">应用方向：</span>{{ item.category }}</span>
                                        </div>
                                    </div>
                                    <div style="height: 42px;padding-left: 10px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">
                                        技术关键词：{{ item.keywords }}
                                    </div>
                                </div>
                                <div class="contact-btn" @click.stop="openZoosUrl">
                                    联系成果
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Page
                        :total="resultTotal"
                        show-elevator
                        :current.sync='matchParams.pageNum'
                        style="margin-top: 50px; float: right;margin-bottom: 20px"
                        @on-change="changePageR"
                />
            </TabPane>
            <TabPane label="推荐科研专利">
                <div class="neirong">
                    <div class="nei_rong_box" style="padding-bottom: 30px">
                        <div class="flex-wrap">
                            <div @click="seePatent(item)" class="result-patent-hover result-hover"
                                 v-for="(item, index) in patentList" :key="index">
                                <div>
                                    <div style="color:#333333;font-size:18px">
                                        <span>{{ item.ti }}</span>
                                    </div>
                                    <div style="margin:10px 0">
                                        <span style="font-size: 14px;background-color: #fcdfc8;color:#fea464;padding:5px 10px;border-radius:4px;margin-right:20px">{{ item.patType }}</span>
                                        <span style="font-size: 14px;background-color: #cadffe;color:#0f62df;padding:5px 10px;border-radius:4px;">{{ item.lastLegalStatus }}</span>
                                    </div>
                                    <div style="border-top:1px dashed #D5E7FA;margin-top:20px; color:#666666">
                                        <div style="margin-top:17px">
                                            <span>专利申请号：</span><span>{{ item.an }}</span>
                                        </div>
                                        <div style="margin-top:17px">
                                            <span class="ellipse-2">归属高校：{{ item.pa }}</span>
                                        </div>
                                        <div style="margin-top:17px">
                                            <span style="height:42px" class="ellipse-2">成果发明人：{{ item.pin }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div @click.stop="openZoosUrl" class="contact-btn">
                                    联系专利
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Page
                        :total="patentTotal"
                        show-elevator
                        style="margin-top: 50px; float: right;margin-bottom: 20px"
                        @on-change="changePageP"
                        :current.sync='matchParams.pageNum'
                />
            </TabPane>
            <TabPane label="企业信息">
                <div class="report-con dp-flex">
                    <div class="report-info">
                        <div class="report-title">基础信息</div>
                        <div class="report-items">
                            <div>
                                <span>企业名称：</span>
                                <span>{{ enterpriseInfo.enterpriseName }}</span>
                            </div>
                            <div>
                                <span>所属科技领域：</span>
                                <span>{{ enterpriseInfo.satTerritory || '--' }}</span>
                            </div>
                            <div>
                                <span>行政区划：</span>
                                <span>{{ enterpriseInfo.administrativeDivision }}</span>
                            </div>
                            <div>
                                <span>企业类型：</span>
                                <span>{{ enterpriseInfo.enterpriseType }}</span>
                            </div>
                            <div>
                                <span>企业地址：</span>
                                <span>{{ enterpriseInfo.site }}</span>
                            </div>
                            <div>
                                <span>营业期限：</span>
                                <span>{{ enterpriseInfo.bussinessTerm }}</span>
                            </div>
                            <div>
                                <span>经营范围：</span>
                                <span>{{ enterpriseInfo.businessScope }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="report-info">
                        <div class="report-title">热词频率</div>
                        <div
                                style="height: 370px; width:350px"
                                ref="wd"
                        ></div>
                    </div>
                </div>
                <div style="padding: 20px 0px 30px 0px; text-align:center">
                    <div @click="getInfoReport"
                         style="width:220px;height:60px;line-height:60px;border-radius: 10px;display:inline-block;margin:0 auto;background-color: #1767E0;cursor: pointer;text-align: center;font-size: 24px;color: white;">
                        下载报告
                    </div>
                </div>
            </TabPane>
        </Tabs>
    </div>
</template>

<script>
    import {get} from "../../../plugins/http/http";
    import {
        isCorrectPassword,
        isPhoneNumber,
        successToast,
        warnToast,
        errorToast,
    } from "@/plugins/tools/util";
    import {mapGetters} from "vuex";
    import {
        getExpertMatch,
        getPatentMatch,
        getResultsMatch,
        getEnterpriseInfo,
        getWordCloud,
    } from "../../../plugins/api/EnterpriseSchoolConnection";
    import {patentUrl, qxtUrl} from "../../../plugins/http/baseUrl";
    import axios from "axios";
    import {getLocalToken} from "../../../plugins/tools/storage";

    export default {
        name: "EnterpriseInformationMatching",
        computed: {
            ...mapGetters({mbrMemberId: 'userStore/mbrMemberId'})
        },
        data() {
            return {
                patentTotal: 0,
                patentNow: 1,
                resultTotal: 0,
                resultNow: 1,
                expertTotal: 0,
                expertNow: 1,
                patentList: [],
                resultList: [],
                expertList: [],
                matchParams: {
                    requestId: "",
                    pageSize: 8,
                    pageNum: 1,
                },
                enterpriseInfo: {
                    enterpriseName: "",
                    administrativeDivision: "",
                    site: "",
                    enterpriseType: "",
                    bussinessTerm: "",
                    satTerritory: "",
                    businessScope: "",
                },
                totalExpertPage: "",
                totalResultPage: "",
                totalPatentPage: "",
                matchPatentParams: {
                    requestId: "",
                    pageSize: 9,
                    pageNum: 1,
                },
                matchResultsParams: {
                    requestId: "",
                    pageSize: 9,
                    pageNum: 1,
                },
                matchExpertParams: {
                    requestId: "",
                    pageSize: 10,
                    pageNum: 1,
                },
                cloudData2: [],
            };
        },

        mounted() {
            this.$parent.changeHeight();
            this.getEnterprise();
            this.getMatchList();
            this.draw()
            this.$parent.changeHeight();
        },
        methods: {
            async getInfoReport() {
                let json = await axios({
                    method: "get",
                    url: `${qxtUrl}/enterprise/exportEnterpriseReport`,
                    responseType: "arraybuffer",
                    headers: {token: getLocalToken()},
                });
                let fileName = "企业信息匹配报告.pdf";
                if (json.status == 200) {
                    let blob = new Blob([json.data], {
                        type: "application/vnd.ms-excel};charset=utf-8",
                    });
                    let downloadElement = document.createElement("a");
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = fileName;
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);
                } else {
                    errorToast(json.message)
                }
            },
            expertDetail(item) {
                let routeUrl = this.$router.resolve({
                    path: "/home/experts",//新页面地址
                    query: {shopId: item.id}//携带的参数
                });
                window.open(routeUrl.href, "_blank");
            },
            color: function () {
                return (
                    "rgb(" +
                    [
                        Math.round(Math.random() * 250),
                        Math.round(Math.random() * 250),
                        Math.round(Math.random() * 250),
                    ].join(",") +
                    ")"
                );
            },
            async draw() {
                get(`${qxtUrl}/enterprise/enterprise/tag?memberId=${this.mbrMemberId}`).then((res) => {
                    if (res.code == 0) {
                        if (res.result.length > 0) {
                            res.result.forEach((item, index) => {
                                this.cloudData2.push({
                                    value: Math.round(Math.random() * 250),
                                    name: item.name,
                                    textStyle: {color: this.color()}
                                })
                            })
                        } else {
                            this.cloudData2.push({
                                value: Math.round(Math.random() * 250),
                                name: "无",
                                textStyle: {color: this.color()}
                            })
                        }

                    }
                    this.$nextTick(() => {
                        this.drawCloud(this.$refs.wd, this.cloudData2)
                    })
                })
            },
            openZoosUrl() {
                openZoosUrl('chatwin');
            },
            async backPage(name) {
                switch (name) {
                    case "result":
                        if (this.resultNow === 1) {
                            return "";
                        } else {
                            this.resultNow -= 1;
                            this.matchParams.pageNum = this.resultNow;
                            let rjson = await getResultsMatch(this.matchParams);
                            if (rjson.code == 0) {
                                this.resultList = rjson.result.list;
                                this.resultTotal = rjson.result.totalPage;
                            } else {
                                warnToast(rjson.message);
                            }
                        }
                        break;
                    case "expert":
                        if (this.expertNow === 1) {
                            return "";
                        } else {
                            this.expertNow -= 1;
                            this.matchParams.pageNum = this.expertNow;
                            let rjson = await getExpertMatch(this.matchParams);
                            if (rjson.code == 0) {
                                this.expertList = rjson.result.list;
                                this.expertTotal = rjson.result.totalPage;
                            } else {
                                warnToast(rjson.message);
                            }
                        }
                        break;
                    case "patent":
                        if (this.patentNow === 1) {
                            return "";
                        } else {
                            this.patentNow -= 1;
                            this.matchParams.pageNum = this.patentNow;
                            let rjson = await getPatentMatch(this.matchParams);
                            if (rjson.code == 0) {
                                this.patentList = rjson.result.list;
                                this.patentTotal = rjson.result.totalPage;
                            } else {
                                warnToast(rjson.message);
                            }
                        }
                        break;
                }
            },
            seePatent(item) {
                let routeUrl = this.$router.resolve({
                    path: "/home/ResourceDetail",//新页面地址
                    query: {an: item.an}//携带的参数
                });
                window.open(routeUrl.href, "_blank");

            },
            seeResult(item) {
                let routeUrl = this.$router.resolve({
                    path: "/home/PatentSearchDetail",//新页面地址
                    query: {id: item.id}//携带的参数
                });
                window.open(routeUrl.href, "_blank");
            },
            async forwardPage(name) {
                switch (name) {
                    case "result":
                        if (this.resultNow === this.resultTotal) {
                            return "";
                        } else {
                            this.resultNow += 1;
                            this.matchParams.pageNum = this.resultNow;
                            let rjson = await getResultsMatch(this.matchParams);
                            if (rjson.code == 0) {
                                this.resultList = rjson.result.list;
                                this.resultTotal = rjson.result.totalPage;
                            } else {
                                warnToast(rjson.message);
                            }
                        }
                        break;
                    case "expert":
                        if (this.expertNow === this.resultTotal) {
                            return "";
                        } else {
                            this.expertNow += 1;
                            this.matchParams.pageNum = this.expertNow;
                            let rjson = await getExpertMatch(this.matchParams);
                            if (rjson.code == 0) {
                                this.expertList = rjson.result.list;
                                this.expertTotal = rjson.result.totalPage;
                            } else {
                                warnToast(rjson.message);
                            }
                        }
                        break;
                    case "patent":
                        if (this.patentNow === this.patentTotal) {
                            return "";
                        } else {
                            this.patentNow += 1;
                            this.matchParams.pageNum = this.patentNow;
                            let rjson = await getPatentMatch(this.matchParams);
                            if (rjson.code == 0) {
                                this.patentList = rjson.result.list;
                                this.patentTotal = rjson.result.totalPage;
                            } else {
                                warnToast(rjson.message);
                            }
                        }
                        break;
                }
            },
            changePageE(index) {
                this.onChangePage('expert', index)
            },
            changePageR(index) {
                this.onChangePage('result', index)
            },
            changePageP(index) {
                this.onChangePage('patent', index)
            },
            async onChangePage(name, index) {
                switch (name) {
                    case "result":
                        var rjson = await getResultsMatch({...this.matchParams, pageSize: 9});
                        if (rjson.code == 0) {
                            this.resultList = rjson.result.list;
                            this.resultTotal = rjson.result.total;
                        } else {
                            errorToast(rjson.message);
                        }
                        break;
                    case "expert":
                        var rjson = await getExpertMatch({...this.matchParams, pageSize: 10});
                        if (rjson.code == 0) {
                            this.expertList = rjson.result.list;
                            this.expertTotal = rjson.result.total;
                        } else {
                            errorToast(rjson.message);
                        }
                        break;
                    case "patent":
                        var rjson = await getPatentMatch({...this.matchParams, pageSize: 9});
                        if (rjson.code == 0) {
                            this.patentList = rjson.result.list;
                            this.patentTotal = rjson.result.total;
                        } else {
                            errorToast(rjson.message);
                        }
                        break;
                }
            },
            onChangeTab() {
                this.matchParams.pageNum = 1
            },
            async getEnterprise() {
                let json = await getEnterpriseInfo();

                if (json.code === 0) {
                    this.enterpriseInfo.businessScope = json.result.businessScope || '--';
                    this.enterpriseInfo.site = json.result.site || '--';
                    this.enterpriseInfo.enterpriseType = json.result.enterpriseType || '--';
                    this.enterpriseInfo.enterpriseName = json.result.enterpriseName || '--';
                    this.enterpriseInfo.bussinessTerm = json.result.bussinessTerm || '--';
                    this.enterpriseInfo.administrativeDivision =
                        json.result.administrativeDivision || '--';
                    this.enterpriseInfo.bussinessTerm = json.result.businessTerm || '--'
                    this.enterpriseInfo.satTerritory = json.result.satTerritory || '--';
                }
            },

            async getMatchList() {
                // this.matchParams.requestId = this.enterpriseId;
                let json = await getPatentMatch({...this.matchParams, pageSize: 9});
                if (json.code == 0) {
                    this.patentList = json.result.list;
                    this.patentTotal = json.result.totalPage;
                } else {
                    warnToast(json.message);
                }

                let rjson = await getResultsMatch({...this.matchParams, pageSize: 9});
                if (rjson.code == 0) {
                    this.resultList = rjson.result.list;
                    this.resultTotal = rjson.result.totalPage;
                } else {
                    warnToast(rjson.message);
                }
                let ejson = await getExpertMatch({...this.matchParams, pageSize: 10});
                if (ejson.code == 0) {
                    this.expertList = ejson.result.list;
                    this.expertTotal = ejson.result.totalPage;
                } else {
                    warnToast(ejson.message);
                }

                let wordCloudList = await getWordCloud({requestId: ""});

                this.nowIndex = 1;
                this.$parent.changeHeight();
                scrollTo(0, 0);
            },
            drawCloud(wrapEl, data) {
                let myChart = this.$echarts.init(wrapEl);
                var option = {
                    tooltip: {
                        show: true,
                    },
                    series: [
                        {
                            name: "热词",
                            type: "wordCloud",
                            sizeRange: [10, 30],
                            rotationRange: [-20, 20],
                            shape: "circle",
                            left: "center",
                            top: "center",
                            width: "100%",
                            height: "100%",
                            gridSize: 3,
                            textPadding: 0,
                            autoSize: {
                                enable: true,
                                minSize: 6,
                            },
                            textStyle: {
                                normal: {
                                    color: function () {
                                        return (
                                            "rgb(" +
                                            [
                                                Math.round(Math.random() * 250),
                                                Math.round(Math.random() * 250),
                                                Math.round(Math.random() * 250),
                                            ].join(",") +
                                            ")"
                                        );
                                    },
                                },
                                emphasis: {
                                    shadowBlur: 10,
                                    shadowColor: "#333",
                                },
                            },
                            data: data,
                        },
                    ],
                };
                myChart.setOption(option);
            },
        },
    };
</script>

<style scoped lang="scss">
    .ivu-select-selection {
        border-radius: 0 !important;
    }

    .arrow_ld {
        display: inline-block;
        position: absolute;
        right: 0;

        & > span {
            display: inline-block;
            height: 20px;
            width: 20px;
            cursor: pointer;
            background-color: #cecece;
            text-align: center;
            line-height: 20px;
            color: white;
        }

        & > span:hover {
            background-color: #37c5cf;
        }
    }

    .qiye,
    .qiye td {
        border: 1px solid #1767E0;
    }

    .qiye {
        border-bottom: none;
        border-right: none;
    }

    .qiye td {
        border-top: none;
        border-left: none;
        text-align: center;
    }

    .span_yyjs {
        display: inline-block;
        text-align: center;
        font-size: 14px;
        height: 24px;
        line-height: 20px;
        width: 64px;
        border-width: 1px;
        background-color: #fcdfc8;
        border-color: #fea464;
        border-style: solid;
        padding: 2px;
        color: black;
        opacity: 0.8;
        overflow: hidden;
        position: absolute;
        right: 20px;
    }

    .qiye td:nth-child(1) {
        width: 100px;
        //text-align: right;
    }

    .qiye td:nth-child(2) {
        width: 310px;
    }

    .qiye td:nth-child(3) {
        width: 130px;
    }

    .qiye td:nth-child(4) {
        width: 260px;
    }

    .span_cg {
        font-size: 18px;
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .common_liucheng {
        width: 95%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        border-bottom: 2px dotted #dfe0f2 !important;

        & > span {
            display: block;
            padding-left: 5px;
            padding-top: 38px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            color: rgb(51, 51, 51);
            margin-bottom: 30px;
        }
    }

    .next_style {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .right_real_content {
        margin: 50px 20px;
        overflow: hidden;
        padding-bottom: 10px;
    }

    .neirong {
        padding: 20px;
        background: #f6faff;

        .contact-btn {
            display: block;
            height: 40px;
            line-height: 40px;
            color: #1767E0;
            text-align: center;
            background: #D5E7FA;
            visibility: hidden;
            border-radius: 0 0 8px 8px;
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
        }

        .result-expert-hover {
            flex: 19%;
            margin: 5px;
            border-radius: 8px;
        }

        .result-ach-hover {
            flex: 32%;
            margin: 5px;
            border-radius: 8px;
        }

        .result-patent-hover {
            flex: 32%;
            margin: 5px;
            border-radius: 8px;
        }
    }

    .report-con {
        padding: 20px;

        .report-info {
            box-shadow: 6px 6px 15px 0px rgba(127, 127, 127, 0.3);
            border-radius: 10px;
            background: #fff;
            padding: 20px;
            width: 49%;
            // height: 400px;
            margin: 0 10px;
        }

        .report-title {
            font-size: 16px;
            line-height: 16px;
            height: 14px;
            color: #333333;
            padding-left: 10px;
            border-left: 6px solid #1767E0;
            margin-bottom: 20px;
            font-weight: bold;
        }

        .report-items {
            > div {
                margin-bottom: 15px;
                margin-left: 20px;
            }
        }
    }

    .result-hover {
        background: #fff;
        box-shadow: 6px 6px 15px 0px rgba(127, 127, 127, 0.3);
        position: relative;

        &:not(.result-expert-hover) {
            padding: 20px 30px 40px 30px;
        }

        &.result-expert-hover {
            .contact-btn {
                position: static;
            }
        }

        &:hover {
            .contact-btn {
                visibility: visible;
            }
        }
    }

    /deep/ .ivu-tabs-tab {
        padding: 15px 0 !important;
        margin-right: 35px !important;
    }

    /deep/ .ivu-tabs-nav-wrap {
        padding-left: 30px;
    }

    /deep/ .ivu-tabs-bar {
        border-bottom: 1px solid #dcdee2 !important;
        margin-bottom: 0;
        box-shadow: 0px 1px 0px 0px #DFE6EC;
    }

    /deep/ .ivu-tabs-tabpane {
        background: #f6faff;
    }
</style>
